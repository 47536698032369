<template>
  <div class="application">
    <!-- 申请详情 -->
    <el-dialog title="申请贷款" @change="$forceUpdate()" :visible.sync="dialogVisible" :before-close="handleClose"
      :close-on-click-modal="false" width="70%">
      <el-form :inline="true" :model="form" label-position="left" :rules="rules" ref="ruleForm">
        <div class="LoanApplication">
          <!-- 服务网点 -->
          <div class="serviceOutlets">
            <el-form-item label="服务网点">
              <el-select @change="LookChange" value-key="departId" v-model="form.OrganizationItem" style="width: 322px">
                <el-option v-for="item in institutions" :key="item.departId" :label="item.departName"
                  :value="item"></el-option></el-select>
            </el-form-item>

          </div>

          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>

            <div class="textContent">
              <div class="Left">

                <el-form-item label="主体类型">
                  <el-select v-model="form.userType" style="width: 200px">
                    <el-option v-for="(item, index) in types" :key="index" :value="item.value"
                      :label="item.label"></el-option></el-select>
                </el-form-item>
                <el-form-item label="申请主体名称">
                  <el-input v-model="form.ApplicationSubjectName" :disabled=true placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="所在地址">
                  <el-cascader style="width: 190px" ref="cascader" v-model="form.areaCode" :props="props"
                    :options="options" :show-all-levels="false" @change="regionChange"></el-cascader>
                </el-form-item>
                <el-form-item label="法人姓名">
                  <el-input v-model="form.LegalPersonName" :disabled=true placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="授权代理人">
                  <el-input v-model="form.agentName" placeholder=""></el-input>
                </el-form-item>

              </div>

              <div class="Right">
                <el-form-item label="统一社会信用代码">
                  <el-input v-model="form.SocialCreditId" :disabled=true placeholder=""></el-input>
                </el-form-item>
                <!-- <el-form-item label="填写详细地址">
                  <el-input v-model="form.identityId" placeholder=""></el-input>
                </el-form-item> -->
                <el-form-item label="手机号">
                  <el-input v-model="form.phone" :disabled=true placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="法人身份证号">
                  <el-input v-model="form.legalPersonNo" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="代理人身份证号">
                  <el-input v-model="form.agentNo" placeholder=""></el-input>
                </el-form-item>

              </div>
            </div>
          </div>

          <!-- 经营信息 -->
          <div class="ManagementInformation">
            <div class="title">
              <p>经营信息</p>
            </div>
            <div class="textContent">
              <div class="textContent_top">
                <div class="Left">
                  <el-form-item label="年经营收入" prop="income">
                    <el-input v-model="form.income" placeholder="" type="number"></el-input>
                  </el-form-item>
                  <!-- <p>元</p> -->
                  <span style="padding-top:10px">
                    元
                  </span>
                  <p>{{ BigYearIncome }}</p>
                </div>
                <div class="Right">
                  <el-form-item label="实际经营年限" prop="YearManagement">
                    <el-input v-model="form.YearManagement" type="number" placeholder=""></el-input>
                  </el-form-item>
                  <p>年</p>
                </div>
              </div>
              <div class="textContent_bottom">
                <span>经营内容</span>
                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容"
                  v-model="form.BusinessContent">
                </el-input>
              </div>
            </div>
          </div>

          <!-- 贷款信息 -->
          <div class="LoanInformation">
            <div class="title">
              <p>贷款信息</p>
            </div>
            <div class="textContent">
              <div class="LoanInformation_top">
                <div class="Left">
                  <el-form-item label="贷款金额" prop="LoansAmount">
                    <el-input :class="showMessage ? 'active' :''" @blur="clearMessage" @focus="clearMessage"
                      v-model="form.LoansAmount" type="number" placeholder=""></el-input>
                    <div v-show="showMessage" style="color: #f56c6c;  font-size: 12px;
                   line-height: 1;
                   padding-top: 4px;
                   position: absolute;
                   top: 100%;
                   left: 0;" class="item__error">{{promptMessage}}</div>
                  </el-form-item>

                  <p>{{ bigNumber }}</p>

                </div>
              </div>
              <div class="LoanInformation_bottom">
                <span>资金用途</span>
                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容"
                  v-model="form.FundsUse">
                </el-input>
              </div>
            </div>
          </div>

          <!-- 营业执照 -->
          <div class="BusinessLicense">
            <div class="title">
              <p>营业执照<i>*</i></p>
            </div>
            <div class="textContent">
              <!-- <img
              :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl"
              alt="暂无图片"
            /> -->
              <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl">
                <div slot="error" class="image-slot">
                  <img src="../../assets/icon/imgError.png" />
                </div>
              </el-image>

              <el-upload class="upload-demo" :action="uploadUrl" name="files" :auto-upload="false"
                :on-change="handleChange" :file-list="fileList" :show-file-list="false">
                <p><el-button type="primary">更换</el-button></p>
              </el-upload>
            </div>
          </div>

          <!-- 法人身份证 -->
          <div class="LegalPersonIDCard">
            <div class="title">
              <p>法人身份证人面像</p>
            </div>
            <div class="Left">
              <!-- <img
              :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl2"
              alt="暂无图片"
            /> -->
              <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl2">
                <div slot="error" class="image-slot">
                  <img src="../../assets/icon/imgError.png" />
                </div>
              </el-image>
              <el-upload class="upload-demo" :action="uploadUrl" name="files" :auto-upload="false"
                :on-change="handleChange2" :file-list="fileList" :show-file-list="false">
                <p><el-button type="primary">更换</el-button></p>
              </el-upload>
            </div>
            <div class="title">
              <p>法人身份证国徽像</p>
            </div>
            <div class="Right">
              <!-- accept="|.png,.jpg," -->
              <!-- <img :src="logoUrl3" alt="暂无图片" /> -->
              <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl3">
                <div slot="error" class="image-slot">
                  <img src="../../assets/icon/imgError.png" />
                </div>
              </el-image>
              <el-upload class="upload-demo" :action="uploadUrl" :limit="6" name="files" :auto-upload="false"
                :on-change="handleChange3" :file-list="fileList" :show-file-list="false">
                <p><el-button type="primary">更换</el-button></p>
              </el-upload>
            </div>
          </div>

          <!-- 其他附件 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>附件</p>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove" :on-change="handleChangeUp" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-download"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in fileList" :key="index">
                <div class="del" @click="del(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 20px" @click="back">取消</el-button>
        <el-button style="margin-top: 20px" @click="SaveBtn">保存草稿</el-button>
        <el-button type="primary" @click="submitBtn"> 提交 </el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  // 图片上传
  import { uploadFile } from "@/api/fileApi";
  //用户申请贷款
  import { ApplicationLoan, AreaTree, selectDeptName } from "@/api/RuralFinance";
  import { digitUppercase } from "@/util/numConvert.js";
  //查询所在地区
  export default {
    data() {
      setTimeout(() => {
        // console.log(this.identityObj, "查看所有的东西");
        this.areaHasChild = this.identityObj.areaCode ? false : true;
        this.form.areaCode = this.identityObj.areaCode;
        // console.log(this.form.areaCode,'查看回显的地址')
        if (this.identityObj.areaCode.length >= 6) {
          //   this.getlandScope(this.identityObj.areaCode.substring(0, 6));
        }
        this.form.areaName = this.identityObj.areaFullName
          ? this.identityObj.areaFullName.split("/").join("")
          : "";
      }, 100);
      //判断贷款的额度是否到达标准
      // var checkLoansAmount = (rule, value, callback) => {
      // // console.log(value,'查看拿到的值')
      //   if (!value) {
      //     return callback(new Error('请输入贷款金额'));
      //   }
      //   setTimeout(() => {
      //       if (value > this.maxMoney) {
      //         callback(new Error('超出贷款额度'));
      //       } else if(value < this.minMoney) {
      //         callback('低于贷款额度');
      //     }
      //     else {
      //     callback();
      //   }
      //   }, 1000);
      // };
      return {
        showApplyFor: false, //判断当前页面是农户还是公司
        fileList: [], //上传图片的列表,
        logoUrl: "", // 存储营业执照
        logoUrl2: "", // 存储人面像图片
        logoUrl3: "", // 存储国徽像图片
        dialogVisible: true,
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        baseUrl: window.globalUrl.HOME_API,
        form: {
          areaCode: "",
          area: "",
          userType: "", //主体类型
          ApplicationSubjectName: "", //申请主体名称
          Address: "", //所在地址
          LegalPersonName: "", //法人姓名
          agentName: "", //授权代理人
          phone: "", //手机号
          SocialCreditId: "", //统一社会信用代码
          // identityId: "", //填写详细地址
          legalPersonNo: "", //法人身份证号
          agentNo: "", //代理人身份证号
          income: "", //年经营收入
          YearManagement: "", //实际经营收入
          BusinessContent: "", //经营内容,
          LoansAmount: "", //贷款金额
          FundsUse: "", //资金用途
          OrganizationItem: "",
        }, //这是输入基础信息
        options: [], // 所在地区
        financeProductId: "", //产品单号
        //主体类型
        types: [
          {
            label: "集体经济组织",
            value: "3",
          },
          {
            label: "农业企业",
            value: "41",
          },
          {
            label: "家庭农场",
            value: "42",
          },
          {
            label: "合作社",
            value: "43",
          },
        ],

        textarea2: "",
        regionOptions: [],
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          fullPathName: "fullPathName",
          checkStrictly: true,
          emitPath: false,
        },
        maxMoney: "", //最大贷款额度
        minMoney: "", // 最小贷款额度
        institutions: [],
        rules: {
          // LoansAmount:[
          //   { validator:checkLoansAmount, trigger: 'change' },
          // ],
          income: [{
            required: true, message: '请输入年经营收入', trigger: 'blur'
          }],
          YearManagement: [{
            required: true, message: '实际经营年限', trigger: 'blur'
          }],
        },
        isTrueMoney: '',
        showMessage: false,//是否显示提示
        promptMessage: '',//提示语
      };
    },
    computed: {
      ...mapGetters(["identityObj"]),
      bigNumber() {
        if (this.form && this.form.LoansAmount) {
          return digitUppercase(this.form.LoansAmount);
        } else {
          return "";
        }
      },
      // maxMoneyTip() {
      //   if (this.form.LoansAmount > this.maxMoney) {
      //     return "*超出贷款金额";
      //   } else if (this.form.LoansAmount < this.minMoney) {
      //     return "*低于贷款金额";
      //   } else {
      //     return "";
      //   }
      // },
      BigYearIncome() {
        if (this.form.income == 0) {
          return "";
        } else {
          return digitUppercase(this.form.income);
        }
      },
    },
    mounted() {
      // console.log(this.form, "这里有值嘛");
      //回显
      this.FirstShow();
    },

    methods: {
      //回显
      FirstShow() {
        //手机号
        this.form.phone =
          this.identityObj.contactPhone || this.identityObj.userName;
        //申请主体名称
        this.form.ApplicationSubjectName = this.identityObj.identityName;
        //社会信用代码
        this.form.SocialCreditId = this.identityObj.identityCode;
        //法人姓名
        this.form.LegalPersonName = this.identityObj.bossName;
        //营业执照
        this.logoUrl = this.identityObj.businessLicense;
        console.log(this.logoUrl);
        //法人身份证人面像
        this.logoUrl2 = this.identityObj.userCardFrontImg;
        console.log(this.logoUrl2);
        //法人身份证国徽像
        this.logoUrl3 = this.identityObj.userCardBackImg;
        console.log(this.logoUrl3);
      },
      LookChange(val) {

        this.institutions.filter(item => {
          if (item.departId == val.departId) {
            return this.financeProductId = item.financeProductId
          }
        })
      },
      //清除消息提示语
      clearMessage() {
        this.promptMessage = "";
        this.showMessage = false;
      },
      //文件上传
      async handleChangeUp(file, fileList) {
        console.log(fileList, "这是fileList");
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;

        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.fileList.push(item);


        // this.files.push(fileList.name);
      },
      handleRemove(file, fileList) {
        this.fileList = fileList;
      },
      //附件上传图片的删除
      del(index) {
        let newFileList = this.fileList.filter((item, ind) => {
          if (index == ind) {
            let newFileList = this.fileList.splice(ind, 1);
            return newFileList;
          }
        });
      },
      //附件下载功能
      async AddFiles(item) {
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = "/api/admin/file/get?ossFilePath=" + item.imgUrl;
        dom.setAttribute("download", item.FilesName);
        document.body.appendChild(dom);
        dom.click();
      },
      //申请详情的弹窗
      handleClose(done) {
        this.$emit("closeDialog", false);
      },
      back() {
        this.$emit("closeDialog", false);
      },
      submit() {
        this.$emit("closeDialog", false);
      },
      // 查看金额
      maxMoneyTip() {
        // 检查输入框的值是否为空
        if (!this.form.LoansAmount.trim() || this.form.LoansAmount == '') {
          this.promptMessage = '请输入贷款金额';
          this.showMessage = true;
          return;
        }

        // 检查输入框的值是否大于最大额度
        if (this.maxMoney != 0) {
          if (parseInt(this.form.LoansAmount) > this.maxMoney) {
            this.promptMessage = '超出贷款额度';
            this.showMessage = true;
            return;
          }

        }




        // 检查输入框的值是否小于最小额度
        if (parseInt(this.form.LoansAmount) < this.minMoney) {
          this.promptMessage = '低于贷款额度';
          this.showMessage = true;
          return;
        }
      },
      //发布
      async submitBtn() {
        this.rules.income[0].required = true
        this.rules.YearManagement[0].required = true
        this.maxMoneyTip()
        this.$refs.ruleForm.validate(async (valid, obj) => {
          if (valid || !this.form.LoansAmount.trim() || !this.form.LoansAmount == '') {
            this.maxMoneyTip()
            if (this.showMessage) return;
            let area = this.$refs["cascader"].getCheckedNodes();
            this.form.area = area[0].data.fullPathName;
            console.log(this.form.OrganizationItem.departId, '查看这是什么数据')
            let params = {
              financeProductId: this.financeProductId,
              departId: this.form.OrganizationItem.departId,
              areaCode: this.form.areaCode,
              area: this.form.area,
              userType: this.form.userType,
              name: this.form.ApplicationSubjectName,
              // address: this.form.identityId,
              idCardNo: this.form.SocialCreditId,
              legalPersonNo: this.form.legalPersonNo,
              phone: this.form.phone,
              userCardFrontImg: this.logoUrl2,
              userCardBackImg: this.logoUrl3,
              files: JSON.stringify(this.fileList),
              income: this.form.income,
              operatYear: this.form.YearManagement,
              money: this.form.LoansAmount,
              use: this.form.FundsUse,
              status: 1,
              legalPersonName: this.form.LegalPersonName,
              LegalPersonId: this.form.LegalPersonId,
              agentName: this.form.agentName,
              agentNo: this.form.agentNo,
              operatIntorducation: this.form.BusinessContent,
              licenseImg: this.logoUrl,
            };
            let res = await ApplicationLoan(params);
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.$router.push("/memberCenter/financalService/applicationRecord");
              this.$emit("closeDialog", false);
            } else {
              this.$message.warning(res.msg);
            }
          } else {
            this.$message.error('请完善提交信息')
          }
        })

      },
      //用户保存贷款草稿
      async SaveBtn() {
        this.rules.income[0].required = false
        this.rules.YearManagement[0].required = false
        this.$refs.ruleForm.resetFields() //此时不会触发表单校验
        // this.$refs.ruleForm.validate(async (valid, obj) => {
        // if(valid){
        let area = this.$refs["cascader"].getCheckedNodes();
        this.form.area = area[0].data.fullPathName;
        let params = {
          financeProductId: this.financeProductId,
          departId: this.form.OrganizationItem.departId,
          areaCode: this.form.areaCode,
          area: this.form.area,
          userType: this.form.userType,
          name: this.form.ApplicationSubjectName,
          // address: this.form.Address,
          idCardNo: this.form.SocialCreditId,
          phone: this.form.phone,
          userCardFrontImg: this.logoUrl2,
          userCardBackImg: this.logoUrl3,
          fileList: this.fileList,
          income: this.form.income,
          operatYear: this.form.YearManagement,
          money: this.form.LoansAmount,
          use: this.form.FundsUse,
          status: 0,
          legalPersonName: this.form.LegalPersonName,
          LegalPersonId: this.form.LegalPersonId,
          agentName: this.form.AuthorizedAgent,
          agentNo: this.form.AuthorizedAgentId,
          operatIntorducation: this.form.BusinessContent,
          licenseImg: this.logoUrl,
        };
        let res = await ApplicationLoan(params);


        // 跳转到申请记录页面
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.$router.push("/memberCenter/financalService/applicationRecord");
          // 关闭弹窗
          this.$emit("closeDialog", false);
        } else {
          this.$message.info(res.msg);
        }
        // }else{
        //     this.$message.error('请完善提交信息')
        //   }
        // })
      },
      //上传
      async allUpLoad(file, type, num) {
        let param = new FormData();
        param.append("catalog", type);
        param.append("file", file.raw);
        let res = await uploadFile(param);
        let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;

        if (num == 1) {
          this.logoUrl = imgUrl;
        } else if (num == 2) {
          this.logoUrl2 = imgUrl;
        } else {
          this.logoUrl3 = imgUrl;
        }
      },
      //上传营业执照
      handleChange(file) {
        this.allUpLoad(file, "BusinessLicense", 1);
      },
      //上传法人身份证人面像
      handleChange2(file) {
        this.allUpLoad(file, "LegalPersonIDCardL", 2);
      },
      //上传法人身份证国徽像
      handleChange3(file) {
        this.allUpLoad(file, "LegalPersonIDCardR", 3);
      },

      //查询所在地址
      async editObj(FinancialDetails) {
        // console.log(FinancialDetails, '查看传过来的详情数值')
        this.maxMoney = FinancialDetails.maxMoney;
        this.minMoney = FinancialDetails.minMoney;
        // this.financeProductId = FinancialDetails.financeProductId;
        let params = {
          // parentCode: FinancialDetails.areaCode,
          parentCode: "",
        };
        let res = await AreaTree(params);
        this.options = res.data;
        this.FinancialDetails = FinancialDetails;
        this.selectDeptName();
      },
      //行政区做切换
      regionChange() {
        let node = this.$refs.cascader.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        this.form.areaCode = node.value;
        this.form.areaName = node.pathLabels.join("");
        this.form.landType = "";

        setTimeout(() => {
          this.$refs.form.validateField("cascader");
        }, 100);
      },
      //查询所属机构
      async selectDeptName() {
        console.log('这里执行了嘛')
        let params = {
          financeProductId: this.FinancialDetails.financeProductId,
        };
        let res = await selectDeptName(params);

        this.form.OrganizationItem = res.data[0];
        this.financeProductId = res.data[0].financeProductId
        // let dataList=[]
        // res.data.forEach((item) => {
        //   dataList.push({
        //     departId:item.departId,
        //     departName:item.departName
        //   })
        // });
        this.institutions = res.data;

      },

      handleChangeAddress(value) {
        console.log(value);
      },
    },
  };
</script>

<style lang="less" scoped>
  .application {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .LoanApplication {
      width: 100%;
      height: 100%;

      /*服务网点*/
      .serviceOutlets {
        .el-form {
          margin-left: 25px;
        }

        /deep/.el-form-item {
          margin-top: 20px;

          .el-form-item__label {
            width: 96px;
            height: 40px;
            font-size: 15px;
            font-weight: bold;
            text-align: center;
          }

          .el-form-item__content {
            width: 322px;
          }
        }

        /*是否平移*/
        /* /deep/.el-form-item__content{
        margin-left: 119px;
      }*/
      }

      /*基础信息*/
      .BasicInformation {
        display: flex;
        border: solid 1px #cccc;

        .title {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          p {
            font-size: 15px;
            font-weight: bold;
            text-align: center;
          }
        }

        .textContent {
          width: 88%;
          display: flex;
          padding: 10px;

          .Left {
            width: 50%;
            height: 100%;

            /deep/.el-form-item {
              width: 100%;

              .el-input {
                width: 200px;
              }

              .el-form-item__label {
                width: 96px;
                height: 40px;
              }
            }
          }

          .Right {
            width: 50%;
            height: 100%;

            /deep/.el-form-item {
              width: 100%;

              .el-input {
                width: 200px;
              }

              .el-form-item__label {
                width: 124px;
                height: 40px;
              }
            }
          }
        }
      }

      /*经营信息*/
      .ManagementInformation {
        display: flex;
        border: solid 1px #cccc;

        .title {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          p {
            font-size: 15px;
            font-weight: bold;
            text-align: center;
          }
        }

        .textContent {
          width: 80%;
          display: flex;
          flex-direction: column;
          padding: 10px;

          .textContent_top {
            width: 100%;
            display: flex;

            .Left {
              width: 45%;
              display: flex;

              p {
                display: flex;
                justify-content: space-between;
                padding: 0px 5px;

                span {
                  i {
                    color: red;
                  }
                }
              }

              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 96px;
                  height: 40px;
                }
              }
            }

            .Right {
              width: 45%;
              display: flex;

              /deep/.el-form-item {

                .el-form-item__label {
                  width: 120px !important;
                }
              }

              p {
                display: flex;
                justify-content: space-between;

                span {
                  i {
                    color: red;
                  }
                }
              }

              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 96px;
                  height: 40px;
                }
              }
            }
          }

          .textContent_bottom {
            width: 100%;
            display: flex;

            span {
              width: 100px;
              margin: 0px 2px;
              line-height: 50px;
            }
          }
        }
      }

      /*贷款信息*/
      .LoanInformation {
        display: flex;
        border: solid 1px #cccc;

        .title {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          p {
            font-size: 15px;
            font-weight: bold;
            text-align: center;
          }
        }

        .textContent {
          width: 80%;
          display: flex;
          flex-direction: column;
          padding: 10px;

          .LoanInformation_top {
            .Left {
              display: flex;

              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 200px;
                }

                .el-form-item__error {
                  color: #f56c6c;
                  font-size: 12px;
                  line-height: 1;
                  padding-top: 4px;
                  position: absolute;
                  top: 100%;
                  left: 0;
                }

                .el-form-item__label {
                  width: 96px;
                  height: 40px;
                }

                .el-form-item__content {
                  .active {

                    input {
                      border: solid 1px #f56c6c;
                    }
                  }
                }
              }

              p {
                margin-left: 10px;
              }

              .Exceed {
                color: red;
              }
            }
          }

          .LoanInformation_bottom {
            width: 100%;
            display: flex;

            span {
              width: 100px;
              line-height: 50px;
            }
          }
        }
      }

      /*营业执照*/
      .BusinessLicense {
        display: flex;
        border: solid 1px #ccc;
        padding: 10px;

        .title {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          p {
            font-size: 15px;
            font-weight: bold;
            text-align: center;
          }
        }

        .textContent {
          width: 48%;
          display: flex;
          justify-content: space-around;
          padding-left: 10px;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding: 5px 0px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }

          img {
            width: 200px;
            height: 100px;
          }

          p {
            width: 94px;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
        }
      }

      /*法人身份证*/
      .LegalPersonIDCard {
        display: flex;
        border: solid 1px #ccc;
        padding: 10px;

        .title {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          p {
            font-size: 15px;
            font-weight: bold;
            text-align: center;

            i {
              color: red;
            }
          }
        }

        .Left {
          width: 50%;
          display: flex;
          padding-left: 50px;
          justify-content: space-around;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding: 5px 0px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }

          p {
            width: 94px;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }

          .title {
            font-size: 15px;
            font-weight: bold;
          }
        }

        .Right {
          width: 50%;
          display: flex;
          justify-content: space-around;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding: 5px 0px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }

          p {
            width: 94px;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }

          .title {
            font-size: 15px;
            font-weight: bold;
          }
        }
      }

      /*其他附件*/
      .OtherAccessories {
        border: solid 1px #ccc;
        display: flex;

        .title {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          p {
            font-size: 15px;
            font-weight: bold;
            text-align: center;

            i {
              color: red;
            }
          }
        }

        .textContent {
          width: 100%;
          height: 120px;
          display: flex;
          border: solid 1px #ccc;

          .Item {
            width: 150px;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            position: relative;
            padding: 10px;

            .el-image {
              width: 150px;
              height: 85px;
              background: #e6e3e3;

              img {
                width: 200px;
                height: 50px;
              }

              i {
                font-size: 50px;
                font-weight: bold;
                margin-top: 20px;
              }
            }

            span {
              margin-top: 5px;
            }

            .del {
              width: 20px;
              height: 20px;
              border: solid 1px black;
              border-radius: 50px;
              display: none;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 5000;
              text-align: center;
              line-height: 20px;
            }
          }

          .Item:hover {
            .del {
              display: block;
            }
          }

          .upload-demo {
            i {
              font-size: 50px;
              font-weight: bold;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
</style>
